<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-2xl">
          Análise de Campanha Voz
        </p>
        <v-card
          flat
        >
          <v-card-text class="pa-3">
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-autocomplete
                  v-model="filterUserId"
                  :items="users"
                  label="Usuário"
                  outlined
                  hide-details
                  dense
                  item-text="value"
                  item-value="id"
                  @change="fetchCampaignVoice"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-3">
            <v-btn
              color="primary"
              class="pl-4"
              @click="fetchCampaignVoice"
            >
              Pesquisar
              <v-icon
                right
                dark
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          flat
          class="mt-5"
        >
          <v-data-table
            :headers="headers"
            :items="rows"
            item-key="id"
            class="table-rounded"
            disable-sort
            dense
          >
            <template #[`item.action`]="{item}">
              <v-btn
                color="info"
                dark
                text
                title="Visualizar"
                x-small
                @click="openDialog(item)"
              >
                <v-icon>{{ icons.mdiEye }}</v-icon>
              </v-btn>
            </template>

            <template #[`item.voiceReleaseStatus`]="{item}">
              <v-chip
                class="my-2"
                :color="resolveStatus(item.voiceReleaseStatus).color"
                small
                label
              >
                <span class="white--text font-weight-semibold">
                  {{ resolveStatus(item.voiceReleaseStatus).value }}
                </span>
              </v-chip>
            </template>

            <template #[`item.campaignCreatedAt`]="{item}">
              <span>{{ $day(item.campaignCreatedAt).format('DD/MM/YYYY HH:mm:ss') }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogTemplate"
      :width="1000"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ `Voz (#${form.campaignId})` }}
          <v-spacer />
          <v-btn
            text
            x-small
            fab
            @click="dialogTemplate = false"
          >
            <v-icon color="error">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="6"
              xs="12"
            >
              <v-text-field
                v-model="form.campaignName"
                outlined
                hide-details
                dense
                label="Campanha"
                readonly
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
              xs="12"
            >
              <v-text-field
                v-model="form.userName"
                outlined
                hide-details
                dense
                label="Usuário"
                readonly
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-center"
                        width="15%"
                      >
                        Dígito
                      </th>
                      <th
                        class="text-left"
                        width="20%"
                      >
                        Ação
                      </th>
                      <th
                        class="text-center"
                        width="40%"
                      >
                        Audio
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ura, index) in audios"
                      :key="index"
                    >
                      <td class="text-center">
                        {{ ura.digit }}
                      </td>
                      <td>
                        {{ ura.description }}
                      </td>
                      <td
                        class="text-center"
                      >
                        <audio
                          style="width: 100%"
                          controls
                        >
                          <source
                            :src="ura.url"
                            type="audio/mpeg"
                          />
                        </audio>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />
        <v-card-actions v-if="form.status === 1">
          <v-spacer />
          <v-btn
            color="error"
            outlined
            @click="dialogTemplateRefuse = true"
          >
            Recusar
          </v-btn>
          <v-btn
            color="success"
            @click="approveTemplate(form.id)"
          >
            Aprovar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <validation-observer
      ref="formRefuseTemplate"
      autocomplete="off"
      tag="form"
    >
      <v-dialog
        v-model="dialogTemplateRefuse"
        :width="400"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Recusar Template
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Motivo"
                  rules="required|max:300"
                  vid="formRefuse.observation"
                >
                  <v-textarea
                    v-model="formRefuse.observation"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    counter
                    outlined
                    dense
                    label="Motivo"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              outlined
              @click="dialogTemplateRefuse = false"
            >
              Fechar
            </v-btn>
            <v-btn
              color="error"
              @click="refuseTemplate()"
            >
              Recusar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  mdiClose,
  mdiEye,
  mdiMagnify,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const users = ref([])
    const filterUserId = ref(null)
    const dialogTemplate = ref(false)
    const audios = ref([])
    const dialogTemplateRefuse = ref(false)
    const form = ref({
      campaignId: ref(null),
      voiceId: ref(null),
      campaignName: ref(null),
      userName: ref(null),
      voiceReleaseStatus: ref(null),
      audios: ref([]),
    })
    const formRefuse = ref({
      campaignId: ref(null),
      voiceId: ref(null),
      observation: ref(null),
    })

    const resolveStatus = status => {
      if (status === 1) return { color: 'info', value: 'Aguardando Análise' }
      if (status === 2) return { color: 'success', value: 'Aprovado' }

      return { color: 'error', value: 'Recusado' }
    }

    return {
      users,
      audios,
      filterUserId,
      dialogTemplate,
      dialogTemplateRefuse,
      form,
      formRefuse,
      icons: {
        mdiClose,
        mdiEye,
        mdiMagnify,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'Status', value: 'voiceReleaseStatus', align: 'center' },
        { text: 'Cód. Campanha', value: 'campaignId' },
        { text: 'Campanha', value: 'campaignName' },
        { text: 'Usuário', value: 'userName' },
        { text: 'Data Criação', value: 'campaignCreatedAt' },
      ],
      resolveStatus,
    }
  },

  computed: {
    ...mapState('reportVoice', [
      'rows',
    ]),
  },

  async created() {
    await this.findUsers()
    await this.fetchCampaignVoice()
  },

  methods: {
    ...mapActions('reportVoice', [
      'find',
      'approve',
      'refuse',
    ]),
    ...mapActions('user', {
      fetchUsers: 'find',
    }),
    ...mapActions('controlFile', {
      getAudio: 'findById',
    }),

    async openDialog(row) {
      this.audios = []
      this.dialogTemplate = true
      this.dialogTemplateRefuse = false

      row.audios.forEach(async data => {
        const audioURL = await this.preview(data.fileId)

        this.audios.push({
          url: audioURL,
          ...data,
        })
      })

      this.form = {
        campaignId: row.campaignId,
        voiceId: row.voiceId,
        campaignName: row.campaignName,
        userName: row.userName,
        status: row.voiceReleaseStatus,
        audios: this.audios,
      }
      this.formRefuse = {
        campaignId: row.campaignId,
        voiceId: row.voiceId,
        observation: '',
      }
    },

    async preview(fileId) {
      const file = await this.getAudio(fileId)

      const uint8Array = new Uint8Array(file.data)
      const blob = new Blob([uint8Array], { type: 'audio/mpeg' })

      return URL.createObjectURL(blob)
    },

    async findUsers() {
      const users = await this.fetchUsers()

      this.users = users.map(user => ({
        ...user,
        value: `${this.$cpfCnpj.toMask(user.document)} - ${user.name}`,
      }))
    },

    async fetchCampaignVoice() {
      await this.find({
        userId: this.filterUserId,
      })
    },

    async approveTemplate() {
      const body = {
        campaignId: this.form.campaignId,
        voiceId: this.form.voiceId,
      }

      const status = await this.approve(body)

      if (status) {
        this.dialogTemplate = false
        this.fetchCampaignVoice()
      }
    },

    async refuseTemplate() {
      const body = {
        campaignId: this.formRefuse.campaignId,
        voiceId: this.formRefuse.voiceId,
        motive: this.formRefuse.observation,
      }

      if (await this.$refs.formRefuseTemplate.validate()) {
        const status = await this.refuse(body)

        if (status) {
          this.dialogTemplateRefuse = false
          this.dialogTemplate = false
          this.fetchCampaignVoice()
        }
      }
    },
  },
}

</script>
